@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');

* {
  /* margin: 0;
   padding: 0; */
  box-sizing: border-box;
}

body {
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

.misc {
  background-color: #010001;
}

.header {
  /* background-color: #561C24; */
  /* padding-right: 50px; */
  /* text-align: right; */
  display: flex;
  justify-content: space-between;
  padding: 25px 45px 25px 45px;
}

.header_right {
  text-align: right;
  padding-right: 60px;
  color: antiquewhite;
}

.header_right:hover {
  color: rgb(181, 80, 80);
}

.about {
  /* background-color: #561C24; */
  text-align: justify;
  margin: 100px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
}

.about h1 {
  text-align: center;
  color: antiquewhite;
}

/* .front_video{
   width: 98%;
   height: 100vh;
 } */

/* .about :nth-child(1) {
  color: rgb(255, 255, 255);
  padding-top: 30px;
}

.about :nth-child(2) {
  color: rgb(255, 255, 255);
} */

.about_mid {
  text-align: center;
  /* border: 1px solid rgb(181, 39, 39); */
  /* padding: 10px 30px 50px 30px; */
  /* padding-top: 30px; */
  /* margin-left: 10%; */
  /* width: 80%; */
  /* height: 60%; */
  /* font-size: large; */
}

/* .about_mid span
    padding: 40px 30px 400px 10px;
    margin-top: 70px;
    color: blue;
    /* margin-bottom: 50px; */

/* #about_mid_text:hover{
    color: #f7f7f7;
    font-size: large; */
/* font-size: larger; */
/* margin: 50px; */
/* border: 1px solid black; */
/* } */

#about_mid_text {
  font-size: larger;
  padding: 10px 20px 10px 20px;
  color: antiquewhite;
  font-weight: 300;
}

.about_whatsapp {
  /* border: 1px solid black; */
  border-radius: 10px;
  font-size: larger;
  font-weight: 500;
  padding: 20px 30px 20px 30px;
  box-shadow: 0 0 70px 10px rgb(98, 10, 10);
  background-color: rgb(93, 11, 11);
  color: beige;
  border: none;
  text-decoration: none;
  margin: 25px 0px 0px 0px;
}

/* #test{
    color: #c51d1d;
 } */

.how_it_works {
  /* display: grid; */
  /* justify-content:space-between; */
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: antiquewhite;
  margin-top: 3%;
}

.how_it_works h1 {
  margin: 150px 0px 30px 0px;
}

.how_it_works_last {
  width: 65%;
  margin: 0% 20% 0% 20%;
  /* padding: 0% 10% 0% 10%; */
  /* justify-content: center; */
  text-align: center;
  font-size: medium;
  color: antiquewhite;
  /* margin-bottom: 50px; */
}

.how_it_works_last p {
  color: antiquewhite;
  font-size: larger;
  /* margin: 0px 0px 20px 70px; */
  /* width: 80%; */
  /* padding-bottom: 50px; */
  margin: 20px 0px 50px 0px;
  /* background-color: whitesmoke; */
}

.about_whatsapp_2 {
  /* border: 1px solid black; */
  border-radius: 10px;
  font-size: larger;
  font-weight: 500;
  padding: 20px 30px 20px 30px;
  box-shadow: 0 0 70px 10px rgb(98, 10, 10);
  background-color: rgb(93, 11, 11);
  color: beige;
  margin-top: 100px;
  text-decoration: none;
}

.why_nataraj {
  margin: 170px 0px 100px 0px;
}

.why_nataraj h1 {
  color: antiquewhite;
  text-align: center;
  /* display: grid; */
  margin: 0px 0px 25px 0px;
}

.why_nataraj_cards {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid black;
  flex-wrap: wrap;
  width: 80%;
  margin-left: 10%;
  font-size: 20px;
}

.test_tilt {
  background-color: #0b0a0a;
  border: 3px solid rgb(66, 10, 10);
  color: antiquewhite;
  max-width: 350px;
  /* height: 300px; */
  min-height: 300px;
  max-height: 350px;
  /* margin-left: 5%; */
  /* margin: 20px 20px 20px 0px; */
  /* padding: 20px 20px 20px 20px; */
  margin-top: 20px;
  text-align: center;
  padding: 40px 30px 0px 30px;
}

.FAQ {
  color: antiquewhite;
}





.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}

.container {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.navbar {
  /* box-shadow: 0px 5px 10px 0px #aaa; */
  width: 100%;
  /* background: #fff; */
  /* color: #000; */
  opacity: 0.85;
  z-index: 100;
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  height: 64px;
  align-items: center;
}

.menu-items {
  order: 2;
  display: flex;
  margin: 20px 0px 15px 0px ;
  /* border: 1px solid white; */
  width: 80%;
  justify-content: space-between;
}

.menu-items :hover{
    color: rgb(93, 11, 11);
}

.logo {
  order: 1;
  /* font-size: 2.3rem; */
  color: #fff;
  height: 150px;
  margin: 10px 0px 15px -50px;
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  font-size: 1.3rem;
}

.navbar a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

/* .navbar a:hover{
    color: #117964;
} */

.accordion-bg-color {
  background-color: #0b0a0a !important;
  color: antiquewhite;
  /* border: 1px solid antiquewhite; */
  outline: none;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: rgb(249, 247, 244);
}

.accordion-button:not(.collapsed)::after {
  outline: antiquewhite;
  color: antiquewhite !important;
}

.accordion-margin {
  padding: 20px 40px;
}

/* .accordion {
	background-color: #eee;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	font-size: 15px;
	transition: 0.4s;
	border-bottom: 1px solid #ccc;
}

.accordion:last-of-type {
	border-bottom: 0;
}

.active,
.accordion:hover {
	background-color: #ccc;
}

.accordion:after {
	content: "\002B";
	color: #777;
	font-weight: 700;
	float: right;
	margin-left: 5px;
}

.active:after {
	content: "\2212";
}

.panel {
	padding: 0 18px;
	background-color: #fff;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}

.accordion-data-height {
    max-height: 86px;
} */

.footer{
    color: antiquewhite;
    display: flex;
    justify-content: center;
    justify-content: space-around;
    margin-top: 20px;
    font-weight: 800;
    font-size: medium;
}

.copyright{
    color: antiquewhite;
    /* justify-content: center; */
    text-align: center;
    padding: 25px 0px 25px 0px;
}

@media (max-width: 768px) {
  .navbar {
    opacity: 0.95;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 28px;
    width: 35px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: rgb(181, 80, 80);
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar .menu-items {
    padding-top: 100px;
    background: black;
    height: 60vh;
    max-width: 300px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    box-shadow: 5px 0px 10px 0px #aaa;
    overflow: scroll;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo {
    position: absolute;
    right: 20px;
    /* top: 5px; */
    bottom: -40px;
    font-size: 2.5rem;
    /* color: rgb(181, 80, 80);; */
    color: #fff;
    height: 100px;
  }

  .about{
    align-items: center;
    width: fit-content;
    margin: 40px 0px 0px 0px;
  }

  /* .about h1{
    padding: ;
  } */

  #about_mid_text{
    font-size: larger;
    width: 90%;
    margin-left: 5%;
  }

  /* .why_nataraj{
    width: 90%;
  } */

  .navbar-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .footer{
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked~.logo {
    display: none;
  }
}


@media (max-width: 768px) {
  .about {
    margin: unset;
  }
}